<template>
  <Sidebar>
    <slot />
  </Sidebar>
</template>

<script setup>
import Sidebar from '~/layouts/sidebar.vue'

useHead({
  title: 'Canopy Portal'
})
</script>
